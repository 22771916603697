
import { mapActions } from 'vuex';
import mixins from '@/utils/mixins';
import auth from '@/mixins/auth';
import CalendarController from '@/components/data/calendar/calendarController/CalendarController.vue';
import JobPublishDrawer from '@/components/data/calendar/JobPublishDrawer.vue';

const baseMixins = mixins(auth);

interface Options extends InstanceType<typeof baseMixins> {
  $refs: {
    files: HTMLInputElement;
    calendar: InstanceType<typeof CalendarController>;
  };
}

export default baseMixins.extend<Options>({ functional: false }).extend({
  components: { CalendarController, JobPublishDrawer },
  data: () => ({
    calendarDrawer: false,
    jobPublishDrawer: false
  }),

  computed: {
    canMoveEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    canUpdateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    canCreateEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    },

    canDeleteEvent(): boolean {
      return this.isUserSupervisor || this.isUserOrganizationAdmin;
    }
  },

  methods: {
    ...mapActions('calendar', ['getOccurrences']),

    prev(): any {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    genCalendarDateTime() {
      this.$refs.calendar.genCalendarDateTime();
    },

    async rebuild() {
      this.jobPublishDrawer = false;
      this.$refs.calendar.genOccurrences();
    }
  }
});
